import React from 'react';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import seLocale from 'date-fns/locale/sv';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import { Control, Controller } from 'react-hook-form';
import { FormHelperText } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material';
import useDynamicTheme from 'hooks/useDynamicTheme';

const validateDate = (date: string | Date) => {
  if (date === null) return false;
  const dateObject = new Date(date);
  return !Number.isNaN(dateObject.getTime()) && dateObject !== null;
};

interface Props {
  control: Control<any, any>;
  name: string;
  label: string;
  defaultValue?: string | Date | null;
  rules?: any;
  style?: React.CSSProperties;
  validateRules?: any;
  helperText?: string;
  isDatetime?: boolean;
  disabled?: boolean;
  noValidate?: boolean;
}

const DateComponent: React.FC<Props> = ({
  control,
  name,
  label,
  defaultValue,
  rules,
  validateRules,
  disabled,
  helperText,
  isDatetime,
  style,
  noValidate = false,
}) => {
  const theme = useDynamicTheme();
  const themeForDate = createTheme({
    ...(theme as any),
    palette: {
      ...theme.palette,
      primary: theme.palette.secondary,
    },
  });

  let ruleObject = {};
  if (!noValidate) {
    ruleObject = rules
      ? {
          ...rules,
          validate: {
            validateDate: (v: string | Date) => (validateDate(v) ? true : 'Invalid date'),
            ...validateRules,
          },
        }
      : {
          validate: {
            validateDate: (v: string | Date) => (validateDate(v) ? true : 'Invalid date'),
            ...validateRules,
          },
        };
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || null}
      rules={ruleObject}
      render={({ field, fieldState }) => {
        const validDate = validateDate(field.value);

        return (
          <ThemeProvider theme={themeForDate}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={seLocale}>
              <div style={{ display: 'flex', flexDirection: 'column', ...style }}>
                {isDatetime ? (
                  <DateTimePicker
                    label={label}
                    value={validDate ? new Date(field.value) : null}
                    onChange={(newValue) => field.onChange(newValue)}
                    disabled={disabled}
                    sx={{
                      width: '100%',
                      '& .MuiFormLabel-root': {
                        color: fieldState.error ? theme.palette.error.main : '',
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: fieldState.error ? theme.palette.error.main : '',
                      },
                    }}
                  />
                ) : (
                  <DatePicker
                    label={label}
                    value={validDate ? new Date(field.value) : null}
                    onChange={(newValue) => field.onChange(newValue)}
                    disabled={disabled}
                    sx={{
                      width: '100%',
                      '& .MuiFormLabel-root': {
                        color: fieldState.error ? theme.palette.error.main : '',
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: fieldState.error ? theme.palette.error.main : '',
                      },
                    }}
                  />
                )}

                {(fieldState.error || helperText) && (
                  <FormHelperText
                    style={{ color: fieldState.error ? theme.palette.error.main : '', marginLeft: '1rem' }}
                  >
                    {fieldState.error ? fieldState.error.message : helperText}
                  </FormHelperText>
                )}
              </div>
            </LocalizationProvider>
          </ThemeProvider>
        );
      }}
    />
  );
};

export default DateComponent;
