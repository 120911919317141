import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { Control, Controller } from 'react-hook-form';

interface Props {
  name: string;
  control: Control<any, any>;
  label: React.ReactNode;
  rules?: any;
  noMargin?: boolean;
}

/**
 * Renders a full-width Check component
 *
 * @param name the variable name
 * @param control control object from useForm
 * @param label label of the input
 */
const CheckComponent: React.FC<Props> = ({ name, control, label, rules = {}, noMargin }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules || null}
      render={({ field, fieldState: { error } }) => (
        <FormControl style={{ display: 'block' }} error={!!error}>
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                style={noMargin ? { paddingTop: '2px', paddingBottom: '2px' } : {}}
                checked={field.value ? field.value : false}
                onChange={(e) => field.onChange(e.target.checked)}
                inputProps={{ 'aria-label': name }}
              />
            }
            label={label}
            style={noMargin ? { width: '100%' } : { width: '100%', marginBottom: '16px', marginTop: '16px' }}
          />
          {Object.keys(rules).length > 0 ? (
            <FormHelperText style={error ? { visibility: 'visible' } : { visibility: 'hidden' }}>
              {error ? error.message : 'none'}
            </FormHelperText>
          ) : null}
        </FormControl>
      )}
    />
  );
};

export default CheckComponent;
